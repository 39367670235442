import React from 'react';
import logo from '../media/b-energy-logo-black.png';

const Header = () => (
  <div className="header">
    <div className="header-container">
      <div className="logo">
        <a href="http://b.energy">
          <img src={logo} alt="b.energy Logo" height="50" />
        </a>
      </div>
    </div>
  </div>
);

export default Header;
