import React from 'react';

const Footer = () => (
    <div className="footer">
    <div className="inner">
        <div className="footer-text">
            <span className="heavy">b.energy A/S</span>
            <br/><br/>
            Telefon: 70 28 40 00 <a href="mailto:kundeservice@b.energy">kundeservice@b.energy</a>
            <br/>
            b.energy A/S CVR: 34608784 
            <br/> 
            Amager Strandvej 403, 2. sal
            <br/>
            2770 Kastrup
        </div>
        <div className="footer-text-small">b.energy er et dansk elselskab som leverer klimavenlig strøm fra vindmøller og solcelleanlæg. Med b.energy er du med til at støtte en grøn omstilling. Vi vil SPARE på og PRODUCERE klimavenlig strøm SAMMEN med vores kunder, med en økonomisk gevinst for alle ombord.</div>
    </div>
</div>
)

export default Footer